var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"1000"},model:{value:(_vm.show_dialog),callback:function ($$v) {_vm.show_dialog=$$v},expression:"show_dialog"}},[_c('div',{staticClass:"neumorphism-pressed"},[_c('div',{staticClass:"topics-list-title-section d-flex justify-space-between align-center py-4 px-8"},[_c('div',{staticClass:"topic-title"},[_vm._v(" Template List ")]),_c('div',[_c('button',{staticClass:"close-btn",on:{"click":function($event){_vm.show_dialog = false}}},[_c('v-icon',{staticClass:"close-icon"},[_vm._v(" mdi-window-close ")])],1)])]),_c('div',{staticClass:"topics-list-table-wrapper"},[_c('div',{staticClass:"d-flex align-center justify-end mb-4"},[_c('button',{staticClass:"add-btn",on:{"click":function($event){return _vm.openAddEditTemplate()}}},[_c('div',{staticClass:"add-icon"},[_vm._v(" Add ")])])]),_c('v-data-table',{staticClass:"neumorphism-flat mt-2",attrs:{"items":_vm.topics_list,"headers":_vm.template_headers,"loading":_vm.table_loading},scopedSlots:_vm._u([{key:"item.body",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"body-text-wrapper"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.body)+" ")])]}}],null,true)},[_c('div',[_vm._v(_vm._s(item.body))])])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"template-image pa-3"},[_c('img',{attrs:{"src":item.image}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"close-btn",on:{"click":function($event){return _vm.openAddEditTemplate(item)}}},[_c('v-icon',{staticClass:"edit-icon"},[_vm._v(" mdi-text-box-edit-outline ")])],1),_c('button',{staticClass:"close-btn ml-4",on:{"click":function($event){return _vm.openDeleteConfirmationDialog(item)}}},[_c('v-icon',{staticClass:"delete-icon"},[_vm._v(" mdi-delete-empty-outline ")])],1)])]}}],null,true)})],1)])]),_c('AddEditTemplate',{ref:"add_edit_template_dialog",on:{"update":_vm.getTemplatesList}}),_c('DeleteConfirmationDialog',{ref:"delete_template_comfirmation_dialog",on:{"update":_vm.getTemplatesList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }